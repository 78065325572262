import styled from 'styled-components'

export default styled.div`
  position: relative;
  background: #18191c;
  border-color: rgba(4,4,5,.3);
  padding: 20px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  transition: border .125s;
`

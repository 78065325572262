export const SET_USER = {
  BEGIN: 'SET_USER_BEGIN',
  SUCCESS: 'SET_USER_SUCCESS',
  FAILURE: 'SET_USER_FAILURE'
}

export const GET_BOT_USER = {
  BEGIN: 'GET_BOT_USER_BEGIN',
  SUCCESS: 'GET_BOT_USER_SUCCESS',
  FAILURE: 'GET_BOT_USER_FAILURE'
}

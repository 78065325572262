export const GET_FEEDS = {
  prefix: 'GET_FEEDS',
  BEGIN: 'GET_FEEDS_BEGIN',
  SUCCESS: 'GET_FEEDS_SUCCESS',
  FAILURE: 'GET_FEEDS_FAILURE'
}

export const ADD_FEED = {
  BEGIN: 'ADD_FEED_BEGIN',
  SUCCESS: 'ADD_FEED_SUCCESS',
  FAILURE: 'ADD_FEED_FAILURE'
}

export const DELETE_FEED = {
  BEGIN: 'DELETE_FEED_BEGIN',
  SUCCESS: 'DELETE_FEED_SUCCESS',
  FAILURE: 'DELETE_FEED_FAILURE'
}

export const EDIT_FEED = {
  BEGIN: 'EDIT_FEED_BEGIN',
  SUCCESS: 'EDIT_FEED_SUCCESS',
  FAILURE: 'EDIT_FEED_FAILURE'
}

export const GET_ARTICLES = {
  prefix: 'GET_ARTICLES',
  BEGIN: 'GET_ARTICLES_BEGIN',
  SUCCESS: 'GET_ARTICLES_SUCCESS',
  FAILURE: 'GET_ARTICLES_FAILURE'
}

export const SEND_FEED_MESSAGE = {
  BEGIN: 'SEND_FEED_MESSAGE_BEGIN',
  SUCCESS: 'SEND_FEED_MESSAGE_SUCCESS',
  FAILURE: 'SEND_FEED_MESSAGE_FAILURE'
}

export const SET_ACTIVE_FEED = 'SET_ACTIVE_FEED'
